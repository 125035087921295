var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;
var exports = {};
!function (e, r) {
  exports = r();
}(exports, function () {
  "use strict";

  function t(e) {
    return (t = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (e) {
      return typeof e;
    } : function (e) {
      return e && "function" == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? "symbol" : typeof e;
    })(e);
  }
  function a(e, r) {
    if (!(e instanceof r)) throw new TypeError("Cannot call a class as a function");
  }
  function e(e, r) {
    if ("function" != typeof r && null !== r) throw new TypeError("Super expression must either be null or a function");
    e.prototype = Object.create(r && r.prototype, {
      constructor: {
        value: e,
        writable: !0,
        configurable: !0
      }
    }), r && i(e, r);
  }
  function o(e) {
    return (o = Object.setPrototypeOf ? Object.getPrototypeOf : function (e) {
      return e.__proto__ || Object.getPrototypeOf(e);
    })(e);
  }
  function i(e, r) {
    return (i = Object.setPrototypeOf || function (e, r) {
      return e.__proto__ = r, e;
    })(e, r);
  }
  function c() {
    if ("undefined" == typeof Reflect || !Reflect.construct) return !1;
    if (Reflect.construct.sham) return !1;
    if ("function" == typeof Proxy) return !0;
    try {
      return Date.prototype.toString.call(Reflect.construct(Date, [], function () {})), !0;
    } catch (e) {
      return !1;
    }
  }
  function s(e, r, t) {
    return (s = c() ? Reflect.construct : function (e, r, t) {
      var n = [null];
      n.push.apply(n, r);
      var o = new (Function.bind.apply(e, n))();
      return t && i(o, t.prototype), o;
    }).apply(null, arguments);
  }
  function r(e) {
    var n = "function" == typeof Map ? new Map() : void 0;
    return (r = function (e) {
      if (null === e || (r = e, -1 === Function.toString.call(r).indexOf("[native code]"))) return e;
      var r;
      if ("function" != typeof e) throw new TypeError("Super expression must either be null or a function");
      if (void 0 !== n) {
        if (n.has(e)) return n.get(e);
        n.set(e, t);
      }
      function t() {
        return s(e, arguments, o(this || _global).constructor);
      }
      return t.prototype = Object.create(e.prototype, {
        constructor: {
          value: t,
          enumerable: !1,
          writable: !0,
          configurable: !0
        }
      }), i(t, e);
    })(e);
  }
  function u(e, r) {
    return !r || "object" != typeof r && "function" != typeof r ? function (e) {
      if (void 0 === e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
      return e;
    }(e) : r;
  }
  function n(t) {
    var n = c();
    return function () {
      var e,
        r = o(t);
      return u(this || _global, n ? (e = o(this || _global).constructor, Reflect.construct(r, arguments, e)) : r.apply(this || _global, arguments));
    };
  }
  function p(e) {
    return function (e) {
      if (Array.isArray(e)) return l(e);
    }(e) || function (e) {
      if ("undefined" != typeof Symbol && Symbol.iterator in Object(e)) return Array.from(e);
    }(e) || f(e) || function () {
      throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
    }();
  }
  function f(e, r) {
    if (e) {
      if ("string" == typeof e) return l(e, r);
      var t = Object.prototype.toString.call(e).slice(8, -1);
      return "Object" === t && e.constructor && (t = e.constructor.name), "Map" === t || "Set" === t ? Array.from(e) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? l(e, r) : void 0;
    }
  }
  function l(e, r) {
    (null == r || r > e.length) && (r = e.length);
    for (var t = 0, n = new Array(r); t < r; t++) n[t] = e[t];
    return n;
  }
  var d = function () {
    e(c, r(Error));
    var i = n(c);
    function c() {
      var e,
        r = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : {},
        t = r.message,
        n = r.type,
        o = r.errors;
      return a(this || _global, c), (e = i.call(this || _global)).name = "CepPromiseError", e.message = t, e.type = n, e.errors = o, e;
    }
    return c;
  }();
  function h(a, s) {
    return s = s || {}, new Promise(function (e, r) {
      var t = new XMLHttpRequest(),
        n = [],
        o = [],
        i = {};
      for (var c in t.open(s.method || "get", a, !0), t.onload = function () {
        t.getAllResponseHeaders().replace(/^(.*?):[^\S\n]*([\s\S]*?)$/gm, function (e, r, t) {
          n.push(r = r.toLowerCase()), o.push([r, t]), i[r] = i[r] ? i[r] + "," + t : t;
        }), e(function e() {
          return {
            ok: 2 == (t.status / 100 | 0),
            statusText: t.statusText,
            status: t.status,
            url: t.responseURL,
            text: function () {
              return Promise.resolve(t.responseText);
            },
            json: function () {
              return Promise.resolve(JSON.parse(t.responseText));
            },
            blob: function () {
              return Promise.resolve(new Blob([t.response]));
            },
            clone: e,
            headers: {
              keys: function () {
                return n;
              },
              entries: function () {
                return o;
              },
              get: function (e) {
                return i[e.toLowerCase()];
              },
              has: function (e) {
                return e.toLowerCase() in i;
              }
            }
          };
        }());
      }, t.onerror = r, t.withCredentials = "include" == s.credentials, s.headers) t.setRequestHeader(c, s.headers[c]);
      t.send(s.body || null);
    });
  }
  var m = function () {
    e(i, r(Error));
    var o = n(i);
    function i() {
      var e,
        r = 0 < arguments.length && void 0 !== arguments[0] ? arguments[0] : {},
        t = r.message,
        n = r.service;
      return a(this || _global, i), (e = o.call(this || _global)).name = "ServiceError", e.message = t, e.service = n, e;
    }
    return i;
  }();
  function v(e, r) {
    return h("https://apps.correios.com.br/SigepMasterJPA/AtendeClienteService/AtendeCliente", {
      method: "POST",
      body: "<?xml version=\"1.0\"?>\n<soapenv:Envelope xmlns:soapenv=\"http://schemas.xmlsoap.org/soap/envelope/\" xmlns:cli=\"http://cliente.bean.master.sigep.bsb.correios.com.br/\">\n  <soapenv:Header />\n  <soapenv:Body>\n    <cli:consultaCEP>\n      <cep>".concat(e, "</cep>\n    </cli:consultaCEP>\n  </soapenv:Body>\n</soapenv:Envelope>"),
      headers: {
        "Content-Type": "text/xml;charset=UTF-8",
        "cache-control": "no-cache"
      },
      timeout: r.timeout || 30000
    }).then(y).catch(P);
  }
  function y(e) {
    return e.ok ? e.text().then(b).then(E) : e.text().then(g).then(w);
  }
  function b(e) {
    try {
      var r;
      return (null !== (r = e.replace(/\r?\n|\r/g, "").match(/<return>(.*)<\/return>/)[0]) && void 0 !== r ? r : "").replace("<return>", "").replace("</return>", "").split(/</).reduce(function (e, r) {
        var t = r.split(">");
        return 1 < t.length && t[1].length && (e[t[0]] = t[1]), e;
      }, {});
    } catch (e) {
      throw new Error("N\xE3o foi poss\xEDvel interpretar o XML de resposta.");
    }
  }
  function g(e) {
    try {
      var r;
      return (null !== (r = e.match(/<faultstring>(.*)<\/faultstring>/)[0]) && void 0 !== r ? r : "").replace("<faultstring>", "").replace("</faultstring>", "");
    } catch (e) {
      throw new Error("N\xE3o foi poss\xEDvel interpretar o XML de resposta.");
    }
  }
  function w(e) {
    throw new Error(e);
  }
  function E(e) {
    return {
      cep: e.cep,
      state: e.uf,
      city: e.cidade,
      neighborhood: e.bairro,
      street: e.end,
      service: "correios"
    };
  }
  function P(e) {
    var r = new m({
      message: e.message,
      service: "correios"
    });
    throw "FetchError" === e.name && (r.message = "Erro ao se conectar com o servi\xE7o dos Correios."), r;
  }
  function C(e, r) {
    return h("https://buscacepinter.correios.com.br/app/endereco/carrega-cep-endereco.php", {
      method: "POST",
      mode: "cors",
      headers: {
        "content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        Referer: "https://buscacepinter.correios.com.br/app/endereco/index.php",
        "Referrer-Policy": "strict-origin-when-cross-origin"
      },
      body: "endereco=".concat(e, "&tipoCEP=ALL"),
      timeout: r.timeout || 30000
    }).then(S).then(j).catch(x);
  }
  function S(e) {
    return e.json().then(function (e) {
      if (0 === e.total || e.erro || "" === e.dados[0].cep) throw new Error("CEP n\xE3o encontrado na base dos Correios.");
      return e;
    });
  }
  function j(e) {
    var r = e.dados[0];
    return {
      cep: r.cep,
      state: r.uf,
      city: r.localidade,
      neighborhood: r.bairro,
      street: r.logradouroDNEC,
      service: "correios-alt"
    };
  }
  function x(e) {
    var r = new m({
      message: e.message,
      service: "correios-alt"
    });
    throw "FetchError" === e.name && (r.message = "Erro ao se conectar com o servi\xE7o dos Correios Alt."), r;
  }
  function O(e, r) {
    var t = "https://viacep.com.br/ws/".concat(e, "/json/"),
      n = {
        method: "GET",
        mode: "cors",
        headers: {
          "content-type": "application/json;charset=utf-8"
        },
        timeout: r.timeout || 30000
      };
    return "undefined" == typeof window && (n.headers["user-agent"] = "cep-promise"), h(t, n).then(A).then(T).then(_).catch(R);
  }
  function A(e) {
    if (e.ok) return e.json();
    throw Error("Erro ao se conectar com o servi\xE7o ViaCEP.");
  }
  function T(e) {
    if (!0 === e.erro) throw new Error("CEP n\xE3o encontrado na base do ViaCEP.");
    return e;
  }
  function _(e) {
    return {
      cep: e.cep.replace("-", ""),
      state: e.uf,
      city: e.localidade,
      neighborhood: e.bairro,
      street: e.logradouro,
      service: "viacep"
    };
  }
  function R(e) {
    var r = new m({
      message: e.message,
      service: "viacep"
    });
    throw "FetchError" === e.name && (r.message = "Erro ao se conectar com o servi\xE7o ViaCEP."), r;
  }
  function F(e, r) {
    var t = "".concat(e.slice(0, 5), "-").concat(e.slice(5));
    return h("https://cdn.apicep.com/file/apicep/".concat(t, ".json"), {
      method: "GET",
      mode: "cors",
      headers: {
        accept: "application/json"
      },
      timeout: r.timeout || 30000
    }).then(L).then(k).then(N).catch(I);
  }
  function L(e) {
    if (e.ok) return e.json();
    throw Error("Erro ao se conectar com o servi\xE7o WideNet.");
  }
  function k(e) {
    if (!1 === e.ok || 200 !== e.status) throw new Error("CEP n\xE3o encontrado na base do WideNet.");
    return e;
  }
  function N(e) {
    return {
      cep: e.code.replace("-", ""),
      state: e.state,
      city: e.city,
      neighborhood: e.district,
      street: e.address,
      service: "widenet"
    };
  }
  function I(e) {
    var r = new m({
      message: e.message,
      service: "widenet"
    });
    throw "FetchError" === e.name && (r.message = "Erro ao se conectar com o servi\xE7o WideNet."), r;
  }
  function M(e, r) {
    return h("https://brasilapi.com.br/api/cep/v1/".concat(e), {
      method: "GET",
      mode: "cors",
      headers: {
        "content-type": "application/json;charset=utf-8"
      },
      timeout: r.timeout || 30000
    }).then(B).then(z).catch(D);
  }
  function B(e) {
    if (!1 === e.ok || 200 !== e.status) throw new Error("CEP n\xE3o encontrado na base do BrasilAPI.");
    return e.json();
  }
  function z(e) {
    return {
      cep: e.cep,
      state: e.state,
      city: e.city,
      neighborhood: e.neighborhood,
      street: e.street,
      service: "brasilapi"
    };
  }
  function D(e) {
    var r = new m({
      message: e.message,
      service: "brasilapi"
    });
    throw "FetchError" === e.name && (r.message = "Erro ao se conectar com o servi\xE7o BrasilAPI."), r;
  }
  function H() {
    return "undefined" != typeof window ? {
      viacep: O,
      widenet: F,
      brasilapi: M
    } : {
      correios: v,
      "correios-alt": C,
      viacep: O,
      widenet: F,
      brasilapi: M
    };
  }
  function U(t) {
    return new Promise(function (e, r) {
      return Promise.resolve(t).then(r, e);
    });
  }
  Promise.any = function (e) {
    return U(Promise.all(p(e).map(U)));
  };
  var V = Promise,
    q = 8;
  function G(e) {
    var r = t(e);
    if ("number" === r || "string" === r) return e;
    throw new d({
      message: "Erro ao inicializar a inst\xE2ncia do CepPromise.",
      type: "validation_error",
      errors: [{
        message: "Voc\xEA deve chamar o construtor utilizando uma String ou um Number.",
        service: "cep_validation"
      }]
    });
  }
  function W(e) {
    return e.toString().replace(/\D+/g, "");
  }
  function X(e) {
    return "0".repeat(q - e.length) + e;
  }
  function J(e) {
    if (e.length <= q) return e;
    throw new d({
      message: "CEP deve conter exatamente ".concat(q, " caracteres."),
      type: "validation_error",
      errors: [{
        message: "CEP informado possui mais do que ".concat(q, " caracteres."),
        service: "cep_validation"
      }]
    });
  }
  function $(e) {
    if (void 0 !== e.length) throw new d({
      message: "Todos os servi\xE7os de CEP retornaram erro.",
      type: "service_error",
      errors: e
    });
    throw e;
  }
  function K(e) {
    var r = e.message,
      t = e.type,
      n = e.errors;
    throw new d({
      message: r,
      type: t,
      errors: n
    });
  }
  return function (e) {
    var o = 1 < arguments.length && void 0 !== arguments[1] ? arguments[1] : {};
    return V.resolve(e).then(G).then(function (e) {
      return o.providers = o.providers ? o.providers : [], function (e) {
        var r = Object.keys(H());
        if (!Array.isArray(e)) throw new d({
          message: "Erro ao inicializar a inst\xE2ncia do CepPromise.",
          type: "validation_error",
          errors: [{
            message: "O par\xE2metro providers deve ser uma lista.",
            service: "providers_validation"
          }]
        });
        var t,
          n = function (e, r) {
            var t;
            if ("undefined" == typeof Symbol || null == e[Symbol.iterator]) {
              if (Array.isArray(e) || (t = f(e)) || r && e && "number" == typeof e.length) {
                t && (e = t);
                var n = 0,
                  o = function () {};
                return {
                  s: o,
                  n: function () {
                    return n >= e.length ? {
                      done: !0
                    } : {
                      done: !1,
                      value: e[n++]
                    };
                  },
                  e: function (e) {
                    throw e;
                  },
                  f: o
                };
              }
              throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
            }
            var i,
              c = !0,
              a = !1;
            return {
              s: function () {
                t = e[Symbol.iterator]();
              },
              n: function () {
                var e = t.next();
                return c = e.done, e;
              },
              e: function (e) {
                a = !0, i = e;
              },
              f: function () {
                try {
                  c || null == t.return || t.return();
                } finally {
                  if (a) throw i;
                }
              }
            };
          }(e);
        try {
          for (n.s(); !(t = n.n()).done;) {
            var o = t.value;
            if (!r.includes(o)) throw new d({
              message: "Erro ao inicializar a inst\xE2ncia do CepPromise.",
              type: "validation_error",
              errors: [{
                message: "O provider \"".concat(o, "\" \xE9 inv\xE1lido. Os providers dispon\xEDveis s\xE3o: [\"").concat(r.join("\", \""), "\"]."),
                service: "providers_validation"
              }]
            });
          }
        } catch (e) {
          n.e(e);
        } finally {
          n.f();
        }
      }(o.providers), e;
    }).then(W).then(J).then(X).then(function (e) {
      return r = e, t = o, n = H(), 0 !== t.providers.length ? V.any(t.providers.map(function (e) {
        return n[e](r, t);
      })) : V.any(Object.values(n).map(function (e) {
        return e(r, t);
      }));
      var r, t, n;
    }).catch($).catch(K);
  };
});
export default exports;